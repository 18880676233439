.customSelect__container{
    /* background-color: aqua; */
    display: flex;
    justify-content: space-between;
    padding: 2.7rem .3rem;
}

.customSelect{
 display: inline-block;   
}

.customSelect label{
    display: block;
    font-size: .8rem;
}


.customSelect select{
    padding: .5rem;
    background-color: transparent;
    border: 1px solid transparent; 
    border-radius: 0.2rem; box-shadow: 0px 2px 2px #aaa;
    min-width: 7rem;
    margin-top: .3rem;
}