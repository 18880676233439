body {
  margin: 0;
  padding: 0;
 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-division:hover{
background-color: rgb(0, 0, 0) !important;
}

.form-articulo{
  width: 65% ;
  margin-left:20%;
  margin-top: 5% !important
}

.form-edit{
  padding-left: 30px !important;
  padding-right:30px !important;
  background-color: rgb(236, 241, 243);
}

#ManageSerialNumbers{
  margin-top:8%
}
.css-5lbw0b-MuiTypography-root{

  margin-top: -5% !important
}

@media  screen and (max-width: 1366px) {
.form-articulo{
  width: 80%;
}
}


.table-container{
margin: 0 auto;
margin-top: 0%;
margin-right: 30px ;
}

#modal-edited{
  
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 30%;
height: 30%;
border-radius: 10px;
box-shadow: 24;
background-color:white;

}

/* (1280x720) Galaxy Note 2, WXGA */
@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {
.form-articulo{
  width: 100%;
}

.table-container{
  width: 100%;
  margin: 0 auto;
}

#modal-edited{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  border-radius: 10px;
  box-shadow: 24;
background-color:white;
}
}



@media only screen and (min-width: 360px) and (max-device-width: 700px)  {
.form-articulo{
  width: 80%;
  margin: 0 auto;
}

.text-table{
  text-align:center;
  padding-top:9%;
  font-size:20px !important
}



#modal-edited{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  border-radius: 10px;
  box-shadow: 24;
background-color:white;
}
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}

.textError{
color: red;
font-weight:bold;


}
.textProv{
color: gray;
margin-top: 5px
}

.plantilla-link{
display: flex;
justify-content: space-between;
width: 97%;
margin-bottom: 1%;
}

.title-edit{
font-size: 40px;
text-align: center;
}


.css-17vbkzs-MuiFormControl-root-MuiTextField-root{
padding-bottom: 10px !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
margin-top: 15px !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
margin-left: 15px !important;
}
.title-create{
  margin-top:0px;
  margin-bottom: 0px;
  font-size:35px;
  text-align:center
}

.container-table{
  margin-top:10% ;
}

.text-table{
  text-align:center;
}

#ItemName {
  text-transform: uppercase !important;
  margin-bottom: 5px ;
}

#ItemName {
  text-transform: uppercase !important;
}

#SupplierCatalogNo {
  text-transform: uppercase !important;
}

#BarCode{
  text-transform: uppercase !important;
}
#react-select-3-input{
  text-transform: uppercase !important;
  height: 40px !important;
}
#react-select-5-input{
  text-transform: uppercase !important;
  height: 40px !important;

}

#react-select-7-input{
  text-transform: uppercase !important;
  height: 40px !important;

}
#react-select-9-input{
  text-transform: uppercase !important;
  height: 40px !important;
}

#react-select-11-input{
  text-transform: uppercase !important;
  height: 40px !important;
}



.css-1s2u09g-control{    
  cursor: pointer !important;
  border: 1px solid white;
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.49) !important;
  height: 57px !important;
  border-color: hsl(0deg 0% 100%) !important

}

.css-1s2u09g-control:hover{    
  border-color:   #1a73e8 !important;
  
}

#modal-modal-title{
  text-align: center !important;;
}

#react-select{
  z-index: 2 !important;
}

#react-select-53-listbox{
  z-index: 2 !important;
}

#react-select-41-listbox{
  z-index: 2 !important;
}

#react-select-3-listbox{
  z-index: 2 !important;
}

#react-select-4-listbox{
  z-index: 2 !important;
}

#react-select-5-listbox{
  z-index: 2 !important;
}

#react-select-6-listbox{
  z-index: 2 !important;
}

#react-select-7-listbox{
  z-index: 2 !important;
}

#react-select-9-listbox{
  z-index: 2 !important;
}

#react-select-11-listbox{
  z-index: 2 !important;
}